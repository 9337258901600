/**
 * 公共js操作
 */
import storage from "@/js/utils/storage";
import CryptoJS from "crypto-js";
export default {
	init: function () {},
	/**取值地址值
	 *
	 * @param key
	 * @returns {*}
	 */
	querystring: function (key) {
		let qss = location.href.replace(location.hash, "").split("?").length > 1 ? location.href.replace(location.hash, "").split("?")[1].split("&") : [];
		for (let i = 0; i < qss.length; i++) {
			if (qss[i].split("=").length > 0 && qss[i].split("=")[0].toString().toLowerCase() == key.toLowerCase()) {
				return qss[i].split("=")[1];
			}
		}
		return "";
	},
	// CryptoJS加密
	encryptDES(str) {
		let cipher = CryptoJS.algo.DES.createEncryptor(CryptoJS.enc.Utf8.parse("BOTWAVEE"), {
			iv: CryptoJS.enc.Utf8.parse("BOTWAVEE"),
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		});
		let encrypted = cipher.finalize(CryptoJS.enc.Utf8.parse(str));
		let encryptStr = encrypted.toString();
		return encryptStr.toUpperCase();
	},
	/**
	 * [getTwoFloat 获取两位浮点数]
	 * @param  {[Number]} floatNum [传入浮点数]
	 * @return {[type]}          [description]
	 */
	getTwoDecimal: function (floatNum) {
		floatNum = String(floatNum);
		if (floatNum.indexOf(".") === -1) {
			return floatNum + ".00";
		} else {
			let _interger = floatNum.split(".")[0];
			let _decimal = floatNum.split(".")[1];
			if (_decimal.length === 1) {
				return _interger + "." + _decimal + "0";
			} else if (_decimal.length >= 2) {
				return _interger + "." + _decimal.slice(0, 2);
			}
		}
		return Number(floatNum);
	},
	/**
	 * [sortBy 排序方法]
	 * @param  {[type]} attr [制定排序属性]
	 * @param  {[type]} rev  [不传为升序，传false为降序]
	 * @return {[type]}      [description]
	 */
	sortBy: function (attr, rev) {
		//第二个参数没有传递 默认升序排列
		if (rev == undefined) {
			rev = 1;
		} else {
			rev = rev ? 1 : -1;
		}
		return function (a, b) {
			a = a[attr];
			b = b[attr];
			if (a < b) {
				return rev * -1;
			}
			if (a > b) {
				return rev * 1;
			}
			return 0;
		};
	},
	/**
	 * @description 优化显示日期
	 * @Author      Yance
	 * @DateTime    2017-11-03
	 * @param       {[type]}   buyDateTime [description]
	 * @return      {[type]}               [今天、昨天]
	 */
	showDate: function (buyDateTime) {
		let tempDate = new Date(buyDateTime.replace(/-/g, "/")),
			nowDate = new Date(),
			startDate,
			endDate,
			dateStr = buyDateTime,
			yesterdayDateStart,
			yesterdayDateEnd,
			time = "",
			hour = "",
			min = "";

		// 今天
		startDate = +new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate());
		endDate = +new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 23, 59, 59);
		//昨天
		yesterdayDateStart = +new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1);
		yesterdayDateEnd = +new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1, 23, 59, 59);

		// 设置时间显示为双位数
		hour = tempDate.getHours() < 10 ? "0" + tempDate.getHours() : tempDate.getHours();
		min = tempDate.getMinutes() < 10 ? "0" + tempDate.getMinutes() : tempDate.getMinutes();
		if (startDate <= +tempDate && +tempDate <= endDate) {
			dateStr = "今天&nbsp;" + hour + ":" + min;
		} else if (yesterdayDateStart <= +tempDate && +tempDate <= yesterdayDateEnd) {
			dateStr = "昨天&nbsp;" + hour + ":" + min;
		}
		return dateStr;
	},
	/**
	 * [getVisibleArea 获取可视区域元素；PS:如果当前元素或其父元素中包含"position:relative;"或是其它定位值，则不能有效的返回可视区域的元素]
	 * @param  {[type]} _Elements [数组元素]
	 * @param  {[type]} diffY   [y轴差值]
	 * @return {[type]}           [返回可视元素数组]
	 */
	getVisibleAreaEle: function (_Elements, diffY) {
		var _visibleEles = [];
		diffY = diffY ? diffY : 0;
		for (var i = 0; i < _Elements.length; i++) {
			var _cOffTop = _Elements[i].offsetTop;
			var isShow = _cOffTop - (document.documentElement.scrollTop || document.body.scrollTop);
			if (diffY <= isShow && isShow <= document.documentElement.offsetHeight) {
				_visibleEles.push(_Elements[i]);
			}
		}
		return _visibleEles;
	},
	/**
	 * @description 显示可视区域元素图片
	 * @Author      Yance
	 * @DateTime    2018-10-16
	 * @param       {[type]}   currImgEleArr [当前图片dom对象数组]
	 * @return      {[type]}                 [description]
	 */
	showVisibleEleImg(currImgEleArr, otherCB) {
		let _self = this;
		let visibleImgEleArr = _self.getVisibleAreaEle(currImgEleArr);
		visibleImgEleArr.forEach((currItem, index) => {
			if (!currItem.getAttribute("src")) {
				currItem.setAttribute("src", currItem.getAttribute("data-img"));
			}
		});

		window.onscroll = function () {
			let visibleImgEleArr = _self.getVisibleAreaEle(currImgEleArr);
			visibleImgEleArr.forEach((currItem, index) => {
				if (!currItem.getAttribute("src")) {
					currItem.setAttribute("src", currItem.getAttribute("data-img"));
				}
			});
			// 其它回调函数
			otherCB && otherCB();
		};
	},
	/* 编辑url参数
	 * url 目标url
	 * arg 需要替换的参数名称
	 * arg_val 替换后的参数的值
	 * return url 参数替换后的url
	 */
	editParams(url, arg, arg_val) {
		let pattern = arg + "=([^&]*)";
		let replaceText = arg + "=" + arg_val;
		if (url.match(pattern)) {
			let tmp = "/(" + arg + "=)([^&]*)/gi";
			tmp = url.replace(eval(tmp), replaceText);
			return tmp;
		} else {
			if (url.match("[?]") && !location.href.indexOf("platform") > 0) {
				return url + "&" + replaceText;
			} else {
				return url + "?" + replaceText;
			}
		}
		return url + "\n" + arg + "\n" + arg_val;
	},
	//base64转文件流blob
	convertBase64UrlToBlob: function (b64Data, contentType, sliceSize) {
		contentType = contentType || "";
		sliceSize = sliceSize || 512;
		var byteCharacters = atob(b64Data.substring(b64Data.indexOf(",") + 1));
		var byteArrays = [];
		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			var byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		var blob = new Blob(byteArrays, {
			type: contentType,
		});

		return blob;
	},
	/**
	 *判断手机机型
	 *
	 * @returns  【android,ios】
	 */
	userAgent() {
		var u = navigator.userAgent;
		var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
		var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
		if (isAndroid) {
			return "android";
		}
		if (isiOS) {
			return "ios";
		}
	},
	/**
	 * @description 监听微信浏览器返回
	 * @Author      Yance
	 * @DateTime    2018-11-02
	 * @param       {[type]}   callBack [description]
	 * @return      {[type]}            [description]
	 */
	historyBack: function (callBack) {
		pushHistory();
		window.addEventListener(
			"popstate",
			function (e) {
				callBack && callBack();
			},
			false
		);

		function pushHistory() {
			var state = {
				title: "title",
				url: "#",
			};
			window.history.pushState(state, "title", location.hash);
		}
	},
	/**
	 * @description 清除用户信息
	 * @Author      Yance
	 * @DateTime    2018-12-14
	 * @return      {[type]}   [description]
	 */
	clearUser() {
		// seesionStorage
		storage.SStorage.remove("_u");
		storage.SStorage.remove("_t");
		storage.SStorage.remove("udata");
		storage.SStorage.remove("_is_uid");
		storage.SStorage.remove("_read");
		storage.SStorage.remove("uid");
		// localStorage
		storage.LStorage.remove("_t");
		storage.LStorage.remove("tokenAge");
	},
	/**
	 * @description 预览图片
	 * @Author      Yance
	 * @DateTime    2018-12-06
	 * @param       {[type]}   currImg [description]
	 * @param       {[type]}   imgList [description]
	 * @return      {[type]}           [description]
	 */
	previewImageFn(currImg, imgList) {
		previewImage && previewImage.start({ urls: imgList, current: currImg });
	},
	/**
	 * [getVisibleArea 获取可视区域元素
	 * @param  {[type]} _Elements [数组元素]
	 * @param  {[type]} diffY   [y轴差值]
	 * @return {[type]}           [返回可视元素数组]
	 */
	getVisibleAreaEleRe: function (_Elements, diffY) {
		let _visibleEles = [];
		diffY = diffY ? diffY : 0;
		for (let i = 0; i < _Elements.length; i++) {
			let _cOffTop = _Elements[i].getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);
			let isShow = _cOffTop - (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);
			if (diffY <= isShow && isShow <= document.documentElement.offsetHeight) {
				_visibleEles.push(_Elements[i]);
			}
		}
		return _visibleEles;
	},
	/**
	 * @description 参数转换
	 * @Author      Yance
	 * @DateTime    2019-11-07
	 * @param       {[type]}   data [description]
	 * @return      {[type]}        [description]
	 */
	convertParams(data) {
		let tempData = {};
		for (let key in data) {
			// 字符串，空字符，数组、null
			if (isNaN(data[key]) || !data[key] || isClass(data[key]).toLowerCase() == "array" || isClass(data[key]).toLowerCase() == "boolean") {
				// 字符串首位去空
				if (isClass(data[key]).toLowerCase() == "string") {
					tempData[key] = data[key].trim();
				} else {
					tempData[key] = data[key];
				}
			}
			// 数字类型
			else {
				tempData[key] = Number(data[key]);
			}
		}

		// 主键为空时，不传该参数
		if (!tempData.Id) {
			delete tempData.Id;
		}

		if (!tempData.Pid) {
			delete tempData.Pid;
		}
		return tempData;
	},
	/**
	 * @description 过滤空数据
	 * @Author      Yance
	 * @DateTime    2019-11-07
	 * @return      {[type]}   [description]
	 */
	filterNullPramas(data) {
		let tempData = {};
		for (let key in data) {
			if (data[key] !== "" && data[key] !== null) {
				tempData[key] = data[key];
			}
		}
		return tempData;
	},
	/**
	 * @description 获取表单中key对应的数据
	 * @Author      Yance
	 * @DateTime    2020年04月10日
	 * @param       {[type]}   dataForm [description]
	 * @param       {[type]}   resData  [description]
	 * @return      {[type]}            [description]
	 */
	getKeysData(dataForm, resData) {
		Object.keys(dataForm).forEach((item, index) => {
			if (resData[item] != undefined) {
				dataForm[item] = resData[item];
			}
		});
	},
	/**
	 * @description 退出登录
	 * @Author      Yance
	 * @DateTime    2019-12-20
	 * @return      {[type]}   [description]
	 */
	logout(callback) {
		storage.LStorage.remove("_t");
		storage.LStorage.remove("_EID");
		storage.LStorage.remove("_E");
		storage.LStorage.remove("_menu");

		callback && callback();
	},
};

/**
 * [deleteParams 删除url参数]
 * @param  {[type]} names [Array类型]
 * @return {[type]}       [description]
 */
window.location.deleteParams = function (names) {
	let loca = this;
	let baseUrl = loca.origin + loca.pathname + "?";
	let query = loca.search.substr(1);
	console.log("delete_url>");
	for (let j = 0; j < names.length; j++) {
		if (query.indexOf(names[j]) > -1) {
			let obj = {};
			let arr = query.split("&");
			for (let i = 0; i < arr.length; i++) {
				arr[i] = arr[i].split("=");
				obj[arr[i][0]] = arr[i][1];
			}
			delete obj[names[j]];
			let url =
				baseUrl +
				JSON.stringify(obj)
					.replace(/[\"\{\}]/g, "")
					.replace(/\:/g, "=")
					.replace(/\,/g, "&")
					.replace(loca.hash, "");
			if (loca.href.indexOf("&") < 0) {
				url = url.replace("?", "");
			}
			return url;
		}
	}
};
/* 编辑url参数
 * url 目标url
 * arg 需要替换的参数名称
 * arg_val 替换后的参数的值
 * return url 参数替换后的url
 */
window.location.editParams = function (url, arg, arg_val) {
	let pattern = arg + "=([^&]*)";
	let replaceText = arg + "=" + arg_val;
	if (url.match(pattern)) {
		let tmp = "/(" + arg + "=)([^&]*)/gi";
		tmp = url.replace(eval(tmp), replaceText);
		return tmp;
	} else {
		if (url.match("[?]")) {
			return url + "&" + replaceText;
		} else {
			return url + "?" + replaceText;
		}
	}
	return url + "\n" + arg + "\n" + arg_val;
};

// 对象深度clone
function clone(obj) {
	let result = {},
		oClass = isClass(obj);
	// if(oClass==="Object"){
	//     result={};
	// }else if(oClass==="Array"){
	//     result=[];
	// }else{
	//     return obj;
	// }
	for (key in obj) {
		let copy = obj[key];
		if (isClass(copy) == "Object") {
			result[key] = arguments.callee(copy);
		} else if (isClass(copy) == "Array") {
			result[key] = arguments.callee(copy);
		} else {
			result[key] = obj[key];
		}
	}
	return result;
}

window.isClass = function (o) {
	if (o === null) return "Null";
	if (o === undefined) return "Undefined";
	return Object.prototype.toString.call(o).slice(8, -1);
};
// 解决精度丢失
window.floatObj = (function () {
	/*
	 * 判断obj是否为一个整数
	 */
	function isInteger(obj) {
		return Math.floor(obj) === obj;
	}

	/*
	 * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
	 * @param floatNum {number} 小数
	 * @return {object}
	 *   {times:100, num: 314}
	 */
	function toInteger(floatNum) {
		let ret = {
			times: 1,
			num: 0,
		};
		let isNegative = floatNum < 0;
		if (isInteger(floatNum)) {
			ret.num = floatNum;
			return ret;
		}
		let strfi = floatNum + "";
		let dotPos = strfi.indexOf(".");
		let len = strfi.substr(dotPos + 1).length;
		let times = Math.pow(10, len);
		let intNum = parseInt(Math.abs(floatNum) * times + 0.5, 10);
		ret.times = times;
		if (isNegative) {
			intNum = -intNum;
		}
		ret.num = intNum;
		return ret;
	}

	/*
	 * 核心方法，实现加减乘除运算，确保不丢失精度
	 * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
	 *
	 * @param a {number} 运算数1
	 * @param b {number} 运算数2
	 * @param digits {number} 精度，保留的小数点数，比如 2, 即保留为两位小数
	 * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
	 *
	 */
	function operation(a, b, digits, op) {
		let o1 = toInteger(a);
		let o2 = toInteger(b);
		let n1 = o1.num;
		let n2 = o2.num;
		let t1 = o1.times;
		let t2 = o2.times;
		let max = t1 > t2 ? t1 : t2;
		let result = null;
		switch (op) {
			case "add":
				if (t1 === t2) {
					// 两个小数位数相同
					result = n1 + n2;
				} else if (t1 > t2) {
					// o1 小数位 大于 o2
					result = n1 + n2 * (t1 / t2);
				} else {
					// o1 小数位 小于 o2
					result = n1 * (t2 / t1) + n2;
				}
				return result / max;
			case "subtract":
				if (t1 === t2) {
					result = n1 - n2;
				} else if (t1 > t2) {
					result = n1 - n2 * (t1 / t2);
				} else {
					result = n1 * (t2 / t1) - n2;
				}
				return result / max;
			case "multiply":
				result = (n1 * n2) / (t1 * t2);
				return result;
			case "divide":
				result = (n1 / n2) * (t2 / t1);
				return result;
		}
	}

	// 加减乘除的四个接口
	function add(a, b, digits) {
		return operation(a, b, digits, "add");
	}

	function subtract(a, b, digits) {
		return operation(a, b, digits, "subtract");
	}

	function multiply(a, b, digits) {
		return operation(a, b, digits, "multiply");
	}

	function divide(a, b, digits) {
		return operation(a, b, digits, "divide");
	}

	// exports
	return {
		add: add,
		subtract: subtract,
		multiply: multiply,
		divide: divide,
	};
})();
// 时间格式化
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
Date.prototype.Format = function (fmt) {
	let o = {
		"M+": this.getMonth() + 1, //月份
		"d+": this.getDate(), //日
		"h+": this.getHours(), //小时
		"m+": this.getMinutes(), //分
		"s+": this.getSeconds(), //秒
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度
		S: this.getMilliseconds(), //毫秒
	};
	if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (let k in o) if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
	return fmt;
};
