export const userDialogHtml =  `<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">更新日期:2023年9月18日</span></font></p><font face="等线"><br/>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">生效日期:2023年9月18日</span></font></p><br/>
<span lang="zh-CN">欢迎您使用国家工业互联网标识解析安全应急行业公共服务平台（以下简称“本平台”），在您使用之前请您务必仔细阅读本平台协议（以下简称“本协议”）。本平台由中国安全产业协会主办、中国安全产业协会</span></font>-<font face="等线"><span lang="zh-CN">消防数智化分会承办，中安工业互联网</span></font>(<font face="等线"><span lang="zh-CN">成都</span></font>)<font face="等线"><span lang="zh-CN">有限公司提供技术支持（以下简称
“我们”）。本协议所述用户（以下简称“您”或“用户”）为访问或使用本平台的任何个人、法人或其他组织。本协议将构成您与我们之间直接有法律约束力的文件。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">一、前言</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">本协议将用以确定、调整和管理在使用服务时作为服务提供商与作为服务使用者的用户之间的法律关系。您使用本平台（包括但不限于注册、登录、或使用等行为）即表示您已充分阅读、理解并同意您已经与我们订立本协议，且您将受本协议的条款和条件（以下简称“条款”）约束。若您是未满</span></font>18<font face="等线"><span lang="zh-CN">周岁的自然人，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用本平台。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">当您使用某一特定服务时，该服务可能会有一些特殊协议。该特殊协议应被视为本协议的补充，是本协议不可分割的组成部分。如本协议与任何特殊协议之间存在分歧，在分歧的范围内以特殊协议约定为准。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">我们可酌情在适用法律和法规允许的限度之内不时修订本协议。如本协议的任何修订对您的权利或义务具有实质影响，我们将通过合理方式提前通知您。如在本协议修订生效之后，您继续使用本平台，则代表您已阅读、理解并无条件同意此类更改。当您与我们发生争议时，应以最新的用户服务协议为准。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">本协议内容包括协议正文及平台已经发布或将发布的各类规则。所有规则为协议不可分割的一部分，与本协议正文具有同等法律效力。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">二、服务使用对象</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">本平台仅供能够根据相关法律订立具有法律约束力的合约的个人或法人或其他组织使用。因此，您的年龄必须在十八周岁或以上，才可使用。如不符合本项条件，请勿单独使用。本平台可随时自行全权决定拒绝向任何人士提供平台信息，包括被暂时或永久停用。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">三、账号注册</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">旨在让您通过一个账号便利享受所有服务，因此为使用之目的，您需要开立一个账号【含下属子账号】（以下统称“账号”）。如您在注册，您同意以下内容：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）根据我们的要求，提供关于您的真实、准确、完整和反映当前情况的资料。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）维持并及时更新资料，使其保持真实、准确、完整和反映当前情况。倘若您提供任何不真实、不准确、不完整或不能反映当前情况的资料，或有合理理由怀疑该等资料不真实、不准确、不完整或不能反映当前情况，如您提供的上述资料不真实、不准确、不完整或不能反映当前情况，导致有关您的任何权益纠纷，我们不对此承担责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">您应时刻注意自己的账号安全（例如避免使用过于简单的用户密码、避免与第三方共用账号）。我们不对任何未经授权使用您账号而导致的损失负责。如您发现任何未经授权的账号使用行为或其他账号安全问题（例如密码被盗）时，您应立即通过平台首页的客服电话联系我们。如果发生了（或有合理理由怀疑发生了）任何未经授权使用您的账号的行为，我们有权停用您的账号，您如有异议，可以向平台客服申诉恢复使用。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">您充分了解并同意，您须为您账号下的一切行为负责。我们不对任何由您账号引起的损害或损失负责。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">您理解并承诺，您设置的帐号不得违反国家法律法规及本平台相关规则，您的帐号名称和简介等用于使用本平台的信息及其他向我们提供的信息中不得出现违法或不良信息，未经他人许可不得使用他人名义（包括但不限于通过冒用他人姓名、名称、字号、商标等足以让人引起混淆的方式）注册帐号，不得恶意注册帐号。您在帐号使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益或者有害社会良好道德风尚的行为。我们有权对您提交的注册信息进行审核，对于违反本条款约定的注册信息，我们有权视情况采取不予注册、屏蔽或删除违法违规内容、不经通知暂停使用、删除帐号等措施，您应自行承担因此导致不能使用帐号或与之相关的服务与功能所造成的损失。如果您违反本协议或进行违法活动，有权停用、删除您的账号。您的账号被删除后没有恢复途径，您应对因此引起的任何和全部损失承担责任（包括因此遭受的损失）。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">若注册账号绑定了企业信息，该账号所有权属于企业。该企业用户向平台客服提供了变更授权资料后，我们有权将注册账号使用的手机号、邮箱、登录密码进行变更。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">四、账号删除</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您的账号一经注册，不可自行注销，只能联系平台客服删除账号。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">如果您确认删除账号，您的账号需同时满足以下条件：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）您所申请删除的账号应当是您依照本平台相关规则注册并由本平台提供给您本人的账号。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）在最近两周内，账号没有进行更改密码、更改绑定信息等敏感操作，账号没有被盗、被封等风险。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）账号在本平台无应当履行而尚未履行完毕的义务。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）账号无任何纠纷，包括投诉举报或被投诉举报。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）账号为正常使用中的账号且当前账号未处于停用状态。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您提交删除账号申请后，该申请不可撤销，因此请您谨慎操作。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">账号删除将导致本平台终止为您提供相关服务，同时您将会丧失以下权益或资产：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）您将无法登录、使用本平台账号；您的身份信息、账号信息（包含名称、订阅记录等）、会员权益、发布的内容（包括但不限于产品、文章、图片、链接、评论等内容）、采购订单将无法找回，您的账号将不可被登录、访问。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）您在删除账号前使用的账号名称、手机号、企业名称等信息将被释放，且允许其他第三方另行在本平台上进行注册使用；且您在使用本平台服务过程中根据本协议、《隐私政策》及其他规则对本平台进行的知识产权授权仍然有效。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）您绑定本平台的第三方账号将会被解绑，且该第三方账号可另行与本平台新的账号进行绑定。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）您将无法再使用本平台账号登录授权或绑定关系的网站。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）您在删除账号前未使用的积分、优惠券等全部信息将会被清零，建议您申请删除前进行使用。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您理解并同意，账号一旦被删除将不可恢复，本平台无法协助您重新恢复前述权益或资产，请您在操作之前，确保您的账号无任何纠纷，并自行备份账号相关的所有信息和数据，否则您应自行承担因所需信息和数据丢失所产生的风险。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">您在确定删除账号后，您的个人信息不在网上公开，但在法律、行政法规规定的保存期限未届满或删除个人信息从技术上难以实现的，我们将无法完全删除您的个人信息。但我们将停止除存储和采取必要的安全保护措施之外的个人信息处理。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">响应删除请求的例外：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">在以下情形中，按照法律法规要求，我们将无法响应您的删除请求：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）与国家安全、国防安全直接相关的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）与公共安全、公共卫生、重大公共利益直接相关的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）与犯罪侦查、起诉、审判和执行判决等直接相关的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）证据表明您可能存在明显恶意、滥用权利及占用平台资源、辱骂客服人员等相关情形的（如您的请求将危害公共安全）；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）您和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>6<font face="等线"><span lang="zh-CN">）响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>7<font face="等线"><span lang="zh-CN">）涉及商业秘密的。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">在您账号删除审核期间，如果您的账号涉及或可能涉及争议、纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，我们有权自行中止或终止本账号的删除而无需另行得到您的同意。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">6.<font face="等线"><span lang="zh-CN">如在账号删除后，我们发现或者接到其他第三方投诉、举报您在删除账号前的行为存在违法违规或其他侵权情形的，我们有权依据法律法规等相关规定进行处理。删除本账号并不代表此账号删除前的账号行为和相关责任得到豁免或减轻。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">7.<font face="等线"><span lang="zh-CN">我们保留在您违反适用法律法规规定或违反本协议的情况下冻结、删除您的帐号并删除或匿名化处理您所存储的数据、文件的权利，且无需为此向您承担任何责任，由此带来的因您使用本平台产生的全部数据、信息等被清空、丢失等的损失，您应自行承担。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">五、收费</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您因进行交易、获取有偿服务而发生的所有应纳税赋，以及相关硬件、软件、通讯、网络服务及其他方面的费用均由您自行承担。我们保留在无须发出书面通知，仅在平台公示的情况下，暂时或永久地更改或停止部分或全部平台服务的权利。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">六</span></font>.<font face="等线"><span lang="zh-CN">用户使用规范</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（一）行为要求</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您应正确配置和使用本平台，并自行采取一定的安全性措施，对您的内容进行保护和备份。您声明并保证您接入本平台的算法、模型、数据、产品、服务的安全性、稳定性、有效性，不包含任何其他软件程序，不存在病毒、蠕虫、木马和其他有害的计算机的代码、文件、脚本和程序，也不存在任何一项恶意软件特征，不会对我们和</span></font>/<font face="等线"><span lang="zh-CN">或其关联方的相关系统造成损害，亦不会对我们和</span></font>/<font face="等线"><span lang="zh-CN">或其关联方或其他第三方数据造成损害。由于您的内容引起的安全漏洞，包括但不限于病毒、木马、蠕虫或其他有害程序，或由于您未按照本协议约定使用服务而引起的安全漏洞，由您自行负责并承担责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN"><b>您使用本平台不得：</b></span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">使用未经我们授权或许可的任何插件、外挂、系统、程序或第三方工具对本平台的正常运行进行干扰、破坏、修改或施加其他影响，包括但不限于使用自动化脚本等方式收集来自本平台的信息或与本平台互动、大量占用本平台系统或者网络带宽资源，给本平台系统或者使用本平台的其他用户的网络、服务器、产品或应用带来严重负荷，影响系统通畅。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">利用或针对本平台进行任何危害计算机网络安全的行为，包括但不限于：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）非法侵入网络、干扰网络正常功能、窃取网络数据等危害网络安全的活动；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）使用未经许可的数据或进入未经许可的服务器</span></font>/<font face="等线"><span lang="zh-CN">帐号；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>6<font face="等线"><span lang="zh-CN">）未经许可，企图探查、扫描、测试本平台系统或网络的弱点或其它实施破坏网络安全的行为；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>7<font face="等线"><span lang="zh-CN">）企图干涉、破坏本平台系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>8<font face="等线"><span lang="zh-CN">）伪造</span></font>TCP/IP<font face="等线"><span lang="zh-CN">数据包名称或部分名称；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>9<font face="等线"><span lang="zh-CN">）复制、模仿、修改、翻译、改编、出借、出售、转许可、在信息网络上传播或转让相关服务，或对本平台及相关服务进行反向工程、反向汇编、编译或者以其他方式尝试发现本平台的源代码；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>10<font face="等线"><span lang="zh-CN">）恶意注册我们帐号，包括但不限于频繁、批量注册帐号；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>11<font face="等线"><span lang="zh-CN">）违反法律、法规、协议、我们的相关规则及侵犯他人合法权益的其他行为。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">未经本平台许可，将本平台用于广告目的或进行任何商业推销或复制、经销、许可、转让、租赁或出售本平台的全部或部分。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">绕过我们可能用于阻止或限制访问服务的任何措施。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">未经本平台许可，将本平台或其任何部分功能纳入任何其他程序或产品。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">6.<font face="等线"><span lang="zh-CN">冒充他人或其他组织机构，或对您自己的身份或您与他人或其他组织机构的关系作出虚假或不当的陈述，包括给人以您上传、发布、传播、散布或提供的任何内容是出自本平台的印象。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">7.<font face="等线"><span lang="zh-CN">未经我们授权而使用或试图使用他人的帐号、服务或系统，或在本平台创建一个虚假身份。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">8.<font face="等线"><span lang="zh-CN">超出正常用户之间信息沟通、交流等目的（包括但不限于为发送广告、垃圾、骚扰或违法违规等信息的目的），使用通过本平台获得的其他用户信息（包括但不限于邮箱、手机号码、解决方案等）。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">9.<font face="等线"><span lang="zh-CN">将本平台的服务或产品用于操作核设施、飞机导航和通讯系统、空中交通管制、生命支持系统、武器系统等类似的高风险场景或某些特定目的。如果您将本平台的服务或者产品用于高风险场景而导致人员伤亡、财产损失或环境破坏，我们不承担责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">10.<font face="等线"><span lang="zh-CN">进行违反法律法规、国家标准、本协议、我们的相关规则及侵犯他人合法权益的其他行为。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（二）信息内容规范</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">您使用本平台开发、制作、利用、上传、评论、发布、传播、存储、分享的内容（包括但不限于上传至本平台的未公开分享的内容）应自觉遵守相关法律法规，否则我们有权立即采取相应处理措施。您不得利用本平台制作、复制、存储、发表、传播下列信息：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）反对宪法确定的基本原则的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）危害国家安全，泄露国家秘密的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）颠覆国家政权，推翻社会主义制度，煽动分裂国家，破坏国家统一的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）损害国家荣誉和利益的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）宣扬恐怖主义、极端主义的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>6<font face="等线"><span lang="zh-CN">）宣扬民族仇恨、民族歧视，破坏民族团结的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>7<font face="等线"><span lang="zh-CN">）煽动地域歧视、地域仇恨的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>8<font face="等线"><span lang="zh-CN">）破坏国家宗教政策，宣扬邪教和封建迷信的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>9<font face="等线"><span lang="zh-CN">）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>10<font face="等线"><span lang="zh-CN">）散布、传播淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>11<font face="等线"><span lang="zh-CN">）危害网络安全、利用网络从事危害国家安全、荣誉和利益的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>12<font face="等线"><span lang="zh-CN">）侮辱或者诽谤他人，侵害他人合法权益的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>13<font face="等线"><span lang="zh-CN">）对他人进行暴力恐吓、威胁，实施人肉搜索的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>14<font face="等线"><span lang="zh-CN">）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>15<font face="等线"><span lang="zh-CN">）散布污言秽语，损害社会公序良俗的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>16<font face="等线"><span lang="zh-CN">）侵犯他人隐私权、名誉权、肖像权、知识产权等合法权益内容的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>17<font face="等线"><span lang="zh-CN">）任何未经我们或接收方同意的广告、招揽推广材料、“垃圾邮件”、“垃圾短信”、“连锁信”、“传销”或任何其它被禁止的招揽形式内容；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>18<font face="等线"><span lang="zh-CN">）侵害未成年人合法权益或者损害未成年人身心健康的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>19<font face="等线"><span lang="zh-CN">）其他违反法律法规、公共政策、社会治安及公序良俗、干扰本平台正常运营或侵犯其他用户或第三方合法权益内容的其他信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">如果我们发现或收到他人举报或投诉用户违反本协议约定的，我们有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、停止或禁止使用部分或全部功能、帐号停用直至删除的处罚，并公告处理结果。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">您的资料和供买卖的产品</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）在通过上传、传送、展示或者以其他方式提供任何信息、材料或其他内容（“用户创建内容”）时，您同意向我们授予不可撤销的、无限制的、可转授权的、世界范围内的、无偿的、永久的以及非排他性的许可，允许以任何方式或者以现在或以后开发的任何媒介将您的用户创建内容用于任何目的（商业、广告或其他目的），包括但不限于对此类用户内容进行使用、复制、修改、编辑、改编、翻译、展示（“用户内容使用许可”）。我们会视其具体使用用户创建内容的情境决定如何署名、使用您提供的用户创建内容。如您对是否以及如何署名、著作权等有专门要求的，请在提供您的用户创建内容时予以公告释明。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）您应对自己提供的用户创建内容，以及由您创建内容所引起的、或与其相联系的任何结果负完全责任（包括因此可能遭受的任何损失）。您特此承诺：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">a.<font face="等线"><span lang="zh-CN">拥有与用户创建内容相关的所有权利，或已被授权；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">b.<font face="等线"><span lang="zh-CN">用户创建内容不侵犯任何知识产权或其他任何第三方的权利（例如您应拥有用户创建内容中的每一位可识别个人的明示同意和</span></font>/<font face="等线"><span lang="zh-CN">或许可，以使用前述每一位可识别个人的姓名或肖像）；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">c.<font face="等线"><span lang="zh-CN">用户创建内容不包含任何病毒或其他有害代码等；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">d.<font face="等线"><span lang="zh-CN">用户创建内容不包含“垃圾邮件”或任何未经同意的营销活动；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">e.<font face="等线"><span lang="zh-CN">用户创建内容不包含任何法律法规、行政规章等（包括但不限于《中华人民共和国网络安全法》、《互联网信息服务管理办法》、《互联网跟帖评论服务管理规定》、《互联网用户账号名称管理规定》）所禁止的其他内容（例如淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的内容），或本协议禁止的内容。</span></font></p>
<p class="western" style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0in">
<font face="等线"><span lang="zh-CN">对于任何违反本条承诺的情况，本平台保留删除或拒绝发布任何用户创建内容或终止您的账号的权利。您保证不招致任何第三方的相关索偿，一旦发生，您同意对因您违反上述保证所遭致的任何损失（包括但不限于平台因处理索偿而支付的律师费）进行赔偿。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）对用户创建内容概不负责。任何用户创建内容中表达的观点并不代表我们的观点或价值观。本平台仅作为分发此类用户创建内容的渠道，并不对任何用户创建内容表示认可或对任何用户创建内容或活动承担任何相关义务或责任。在任何情况下，您不应轻信借款、投资、索要密码或其他涉及财产的用户创建内容。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）我们没有责任去认定或决定您提交给本公司的资料哪些是应当受到保护的，对平台的其他用户使用“您的用户创建内容”，也不必负责。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">交易系统</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）不得操纵交易。您同意不利用帮助实现蒙蔽或欺骗意图的同伙（下属的客户或第三方），操纵与另一交易方所进行的商业谈判的结果。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）系统完整性。您同意，您不得使用任何装置、软件或程序干预或试图干预的正常运作或正在平台上进行的任何交易。您不得采取对任何将不合理或不合比例的庞大负载加诸本公司网络结构的行动。您不得向任何第三者披露您的密码，或与任何第三者共用您的密码，或为任何未经批准的目的使用您的密码。您应当对您在本网站账号上的操作承担全部责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）不作商业性利用。您同意，您不得对本平台任何资料作商业性利用，包括但不限于在未经事先书面批准的情况下，复制在平台上展示的任何资料并用于商业用途。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">七、处理交易争议</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">本平台不涉及用户间因交易而产生的法律关系及法律纠纷，不会且不能牵涉进交易各方的交易当中。倘若您与一名或一名以上用户，或与您通过获取其服务的第三者服务供应商发生争议，您免除（我们及我们的代理人和雇员）在因该等争议而引起的，或在任何方面与该等争议有关的不同种和性质的任何（实际和后果性的）权利主张、要求和损害赔偿等方面的责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">本平台有权受理并调处您与其他用户因交易产生的争议，同时有权单方面独立判断其他用户对您的投诉及（或）索偿是否成立，若判断索偿成立，则您应及时使用自有资金进行偿付，否则有权使用您交纳的保证金（如有）或扣减已购服务中未履行部分的费用的相应金额或您在所有账号下的其他资金（或权益）等进行相应偿付。我们没有使用自用资金进行偿付的义务，但若进行了该等支付，您应及时赔偿的全部损失，否则有权通过前述方式抵减相应资金或权益，如仍无法弥补的损失，则保留继续追偿的权利。因非司法机构，您完全理解并承认，对证据的鉴别能力及对纠纷的处理能力有限，受理贸易争议完全是基于您之委托，不保证争议处理结果符合您的期望，亦不对争议处理结果承担任何责任。我们有权决定是否参与争议的调处。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">有权通过电子邮件、手机号码等联系方式向您了解情况，并将所了解的情况通过电子邮件等方式通知对方，您有义务配合工作，否则有权做出对您不利的处理结果。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">经生效法律文书确认您存在违法或违反本协议行为或者自行判断您涉嫌存在违法或违反本协议行为的，我们有权在平台公布您的违法行为并做出处罚（包括但不限于限权、停用或删除账号等）。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">八、账号安全</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">用户应在登录本平台时，以正确步骤离开网站或小程序等应用服务。用户如果发现有他人冒用或盗用本平台登录名及密码或任何其他未经合法授权之情形，或发生与本平台账号关联的手机或其他设备遗失或其他可能危及到本平台账号安全情形时，应立即以有效方式通知本平台，向本平台申请账号锁定。您理解本平台对您的请求采取行动需要合理期限，在此之前，本平台对已执行的指令及</span></font>(<font face="等线"><span lang="zh-CN">或</span></font>)<font face="等线"><span lang="zh-CN">所导致的您的损失不承担任何责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">用户应对其持有的本平台账号负责，只有用户方可使用该账号。该账号不可转让、不可继承，在您决定不再使用该账号时，您应将该账号内的数据导出或删除，并按本平台规定流程向本平台申请删除该账号。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">本平台可以暂时停止提供或者限制本服务部分功能，或提供新的功能，在任何功能减少、增加或者变化时，只要用户仍然使用本服务，即表示同意本协议或者变更后的协议。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">本平台对因用户未能遵守本协议的约定而发生的任何直接或间接损失等不利后果不承担任何责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">本平台有权了解您使用本平台产品或服务的真实交易背景及目的，用户应如实提供本平台所需的真实、全面、准确的信息。如果本平台有合理理由怀疑用户提供虚假信息、进行欺诈等违法违规行为的，本平台有权根据相关国家法律法规的规定对用户信息以及签署的文件信息等进行核实、查询、披露，有权锁定相应账号并配合相关机关进行后续调查。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">九、终止或访问限制</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">您同意，在未向您收费的情况下，我们可自行全权决定以任何理由（包括但不限于认为您已违反本协议的约定，或您以不符合本协议约定的方式行事，或您在超过</span></font>180<font face="等线"><span lang="zh-CN">天的时间内未以您的账号及密码登录）删除您的本平台密码、账号（或其任何部份）或您对本平台的使用，并删除和丢弃您在使用本平台中提交的“您的资料”。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">您同意，在向您收费的情况下，我们应基于合理的怀疑您违约且经电子邮件通知的情况下实施上述终止服务的行为。同时可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供平台服务或其任何部份功能。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">您同意，在不限制其他补救措施的前提下，发生下述任一情况，本平台可立即发出警告，暂时停用或删除您的账号，删除您的任何现有产品信息，以及您在展示的任何其他资料：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）您违反本协议；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）无法核实或鉴定您向我们提供的任何资料是否真实；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）相信您的行为可能会使您、用户或通过提供服务的第三方服务供应商发生任何法律责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">在不限制任何其他补救措施的前提下，倘若发现您从事涉及的诈骗活动，可停用或删除您的账号。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">您同意，我们不会就停用或删除您账号导致无法接入平台而对您或任何第三者承担任何责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">当您违反本协议或与签订的其他协议的约定，我们有权以任何方式通知关联主体，要求其对您的权益采取限制措施包括但不限于要求将您账号下的款项支付给指定的对象，要求关联主体中止或终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">十、免责声明</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">服务“按现状”提供</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">我们会尽一切努力使您正常地使用平台提供的服务。遗憾的是，不能随时预见到任何技术上的问题或其他困难。该等困难可能会导致数据损失或其他服务中断。为此，您明确理解和同意，您使用的风险由您自行承担。以“按现状”和“按可得到”的基础提供。我们明确声明不作出任何种类的所有明示或暗示的保证，包括但不限于关于适销性、适用于某一特定用途和无侵权行为等方面的保证。对下述内容不作保证：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）会符合您的要求；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）网站不会中断，且适时、安全和不带任何错误；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）通过使用而可能获取的结果将是准确或可信赖的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）您通过而购买或获取的任何产品、服务、资料或其他材料的质量将符合您的预期。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">通过使用而下载或以其他形式获取任何材料是由您自行全权决定进行的，且与此有关的风险由您自行承担，对于因您下载任何该等材料而发生的您的电脑系统的任何损毁或任何数据损失，您将自行承担责任。您从本平台上获取的任何口头或书面意见或资料，均不产生未在本协议内明确载明的任何保证。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">责任范围</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您明确理解和同意，我们不对因下述任一情况而发生的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据、隐私泄漏等方面的损失或其他无形损失的损害赔偿（无论是否已被告知该等损害赔偿的可能性）：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）使用或未能使用平台；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）因通过或从平台购买或获取任何货物、样品、数据、资料或服务，或通过或从平台接收任何信息或缔结任何交易所产生的获取替代货物和服务的费用；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）未经批准接入或更改您的传输资料或数据等隐私信息；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）任何第三者的声明或相关的行为（包括但不限于第三方利用本平台获取的用户资料等隐私信息）；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）本平台若因线路及非本平台控制范围外的硬件故障或其它不可抗力，以及黑客政击、计算机病毒侵入或发而造成的用户隐私资料泄露、丢失、被盗用或被篡改等；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>6<font face="等线"><span lang="zh-CN">）因任何原因而引起的有关的任何其他事宜，包括疏忽。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">链接</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">我们或第三者均可提供与其他网站或资源链接。由于我们并不控制该等网站和资源，您承认并同意：我们并不对该等外在网站或资源的可用性负责，且不认可该等网站或资源上或可从该等网站或资源获取的任何内容、宣传、产品、服务或其他材料，也不对其等负责或承担任何责任。您进一步承认和同意，对于任何因使用或信赖从此类网站或资源上获取的此类内容、宣传、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失（包括但不限于隐私信息泄露导致的损失），均不承担责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">广告和金融服务</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您与我们在或通过物色的刊登广告人士通讯或进行业务往来或参与其推广活动，包括就相关产品或服务付款和交付相关产品或服务，以及与该等业务往来相关的任何其他条款、条件、保证或声明，仅限于在您和该刊登广告人士之间发生。您同意，对于因任何该等业务往来或因在出现该等刊登广告人士而发生的任何种类的任何损失或损毁，无需负责或承担任何责任。您如打算通过创设或参与任何公司、股票行情、投资或证券有关的任何服务，或通过收取或要求与任何公司、股票行情、投资或证券有关的任何新闻信息、警戒性信息或其他资料，敬请注意，不会就通过平台传送的任何该等资料的准确性、有用性或可用性、可获利性负责或承担任何责任，且不会对根据该等资料而作出的任何交易或投资决策负责或承担任何责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">不可抗力</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">对于因合理控制范围以外的原因，包括但不限于自然灾害、罢工或骚乱、物质短缺或定量配给、暴动、战争行为、政府行为、通讯或其他设施故障或严重伤亡事故等，致使延迟或未能履约的，我们不对您承担任何责任。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">十一、赔偿</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您同意，因您违反本协议或经在此提及而纳入本协议的其他文件，或因您违反了法律或侵害了第三方的权利，而使第三方对我们或其关联主体提出索赔要求（包括司法费用、鉴定费、律师费和其他专业人士的费用），您必须赔偿给我们或关联主体，使其等免遭损失。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">十二、其他约定</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">无代理关系</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您与我们仅为独立订约人关系。本协议无意结成或创设任何代理、合伙、合营、雇用与被雇用或特许经营权授予与被授予关系。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">转让</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">我们转让本协议的权利义务无需经您同意。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">本协议各方面应受中华人民共和国大陆地区法律的管辖。倘若本协议任何规定被裁定为无效或不可强制执行，该项规定应被撤销，而其余规定应予执行。条款标题仅为方便参阅而设，并不以任何方式界定、限制、解释或描述该条款的范围或限度。未就您或其他人士的某项违约行为采取行动，并不表明丧失就任何继后或类似的违约事件采取行动的权利。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">本协议的解释权归中安工业互联网（成都）有限公司所有。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">因本协议或服务所引起或与其有关的任何争议应向成都仲裁委员会提起仲裁。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">6.<font face="等线"><span lang="zh-CN">为给用户提供更好的服务或遇到国家法律法规、政策调整，本协议将不时更新与变化，平台会适时对本协议进行修订，这些修订构成本协议的一部分。本协议更新后，我们会发出更新后的版本，并在更新后的条款生效前通过官方网站公告、平台消息、或其他适当的方式提醒用户更新的内容，以便用户及时了解本协议的最新版本，用户也可以在平台页面查阅最新版本的协议条款。如用户继续使用平台及相关服务，表示同意接受修订后的本协议的内容。</span></font></p>
<div style="height:100px"></div>`


// 隐私政策
export const privicyHtml = `<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">更新日期:2023年9月20日</span></font></p><font face="等线"><br/>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">生效日期:2023年9月20日</span></font></p><font face="等线"><br/><span lang="zh-CN">本隐私政策仅适用于由中国安全产业协会主办、中国安全产业协会</span></font>-<font face="等线"><span lang="zh-CN">消防数智化分会承办，中安工业互联网（成都）有限公司提供技术支持（以下简称
“我们”）的国家工业互联网标识解析安全应急行业公共服务平台（以下简称“本平台”）提供的产品和服务。本平台涵盖安全应急行业、区域子站及全部产品</span></font>&amp;<font face="等线"><span lang="zh-CN">解决方案，包含企业动态、公司荣誉、优秀样板案例等信息。平台定位为引入用户全流程参与体验基于标识解析的安全应急行业公共服务平台，为不同行业和规模的企业提供面向场景的数字化转型解决方案，推动生产方式、商业模式、管理范式的变革，促进新模式、新业态的普及，构建“政、产、学、研、用、金”共创共享、高质量发展的工业新生态。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">如果您有任何疑问、意见或建议，请通过以下任一联系方式与我们联系：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">电子邮件
：</span></font>csin@csin.net.cn</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">电
话 ：</span></font>028-60911886 
</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">地址：中国（四川）自由贸易试验区成都高新区天府大道北段</span></font>869<font face="等线"><span lang="zh-CN">号</span></font>1<font face="等线"><span lang="zh-CN">栋</span></font>1<font face="等线"><span lang="zh-CN">单元</span></font>20<font face="等线"><span lang="zh-CN">楼</span></font>01-03<font face="等线"><span lang="zh-CN">号</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">我们非常重视您的信任，并深知账号信息对于您的重要性，我们将按照法律法规的规定，保护您的账号信息安全。我们将恪守以下原则，保护您的账号安全：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">【特别提示】</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">请您仔细阅读《国家工业互联网标识解析安全应急行业公共服务平台隐私政策》（以下简称“本隐私政策”）并确定了解我们对您账号信息的处理规则。阅读过程中，如您有任何疑问，可及时与本平台客服联系。如您选择不同意协议中的任何条款，您应立即停止访问本平台。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"> 
</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">本平台非常重视用户的隐私和账号信息保护。您在使用我们的产品与</span></font>/<font face="等线"><span lang="zh-CN">或服务时，我们可能会收集和使用您的相关信息。我们希望通过本隐私政策向您说明在您使用我们的产品与</span></font>/<font face="等线"><span lang="zh-CN">或服务时，我们如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">为了保护本平台用户隐私权，优化用户体验，本平台制定本隐私政策。本隐私政策旨在帮助您了解我们会收集哪些数据、为什么收集这些数据，会利用这些数据做些什么及如何保护这些数据。我们希望您在使用本平台服务前仔细阅读并明确您已经充分理解、接受本隐私政策的内容，希望您可以根据自己的理解做出合适的选择。您同意隐私政策仅代表您已了解本平台提供的功能，以及功能运行所需的必要账号信息，并不代表您已同意我们可以收集非必要账号信息，非必要账号信息会根据您使用过程中的授权情况单独征求您的同意。在我们更新本隐私政策后，您继续使用我们的产品和</span></font>/<font face="等线"><span lang="zh-CN">或服务，即意味着您同意本隐私政策（含更新版本）内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。此外，当我们将您的账号信息用于本隐私政策未涵盖的用途时，我们会事先征求您的同意。请您特别注意：本隐私政策适用于我们以平台以及随技术发展出现的新形态向您提供的各项产品和服务。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"> 
</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">一、我们如何收集和使用您的账号信息</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">在您使用本平台提供的服务过程中，我们将收集以下必要的相关账号信息，以及部分您可以选择提供的账号信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">注册、登录与账号信息维护</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">第一，当您注册、登录本平台时，您需要通过手机号或邮箱、密码创建账号，并且您可以完善相关的网络身份识别信息（名称、密码）。如果您仅浏览本平台官网，您不需要注册本平台账号并提供上述信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">第二，当您代表企业注册本平台时，您可能还需提供企业</span></font>/<font face="等线"><span lang="zh-CN">组织主体证照等基本信息，以便我们帮助企业完成认证。您确认已获得这类企业的充分授权签署并同意本隐私政策的全部内容。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">第三，当您进行账号信息维护时，您可能需要提供注册电话号码、邮箱地址、公司授权证明、公司名称、公司地址，此类信息为必填项。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">短信、邮件通知</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您在使用我们的平台服务时，我们可能使用您的信息向您的设备发送电子邮件、新闻或推送通知。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择取消订阅。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">标识扫码解析</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">在您进行标识扫码解析时，我们会收集您的</span></font>ip<font face="等线"><span lang="zh-CN">地址和经纬度信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">解决方案咨询</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">当您需要咨询解决方案时，您需要向本平台客服提供联系人姓名、联系手机号、公司名称等相关信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">文档下载</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">当您进行文档下载时，我们会获取您的姓名、个人电话号码、公司名称等相关信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">6.<font face="等线"><span lang="zh-CN">用户登录日志</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">为了更好地向您提供服务，我们会收集您的用户登录日志，包括但不限于登录时间、用户名、手机号。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">7.<font face="等线"><span lang="zh-CN">建议反馈</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">当您向本平台客服提供建议反馈时，我们会收集您的姓名和手机号、邮箱信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">8.<font face="等线"><span lang="zh-CN">供需发布</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">当您作为企业进行供需发布时，我们会收集您的信息，包含但不限于姓名、手机号、公司名称。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">9.<font face="等线"><span lang="zh-CN">为您提供安全保障</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">为提高您使用我们及关联主体提供产品或服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或相关协议规则的情况，我们可能使用或整合您的账号信息、交易信息、设备信息、有关网络日志以及关联主体取得您授权或依据法律共享的信息，来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">10.<font face="等线"><span lang="zh-CN">其他用途</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">我们将信息用于本隐私政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">11.<font face="等线"><span lang="zh-CN">征得授权同意的例外</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的账号信息：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）为订立、履行您作为一方当事人的合同、标识证书申请、存证申请所必需；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）为履行法定义务所必需；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）为应对突发公共卫生事件，或者紧急情况下为保护个人的生命健康和财产安全所必需；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理账号信息；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）在合理的范围内处理您自行公开或者其他已经合法公开的账号信息；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>6<font face="等线"><span lang="zh-CN">）法律、行政法规规定的其他情形。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">根据法律规定，共享、转让经去匿名化处理的账号信息，且确保数据接收方无法复原并重新识别账号信息主体的，不属于账号信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">二、我们如何使用</span></font>Cookie<font face="等线"><span lang="zh-CN">和同类技术</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">为确保平台正常运转，我们会在您的计算机或移动设备上存储名为</span></font>cookie<font face="等线"><span lang="zh-CN">的数据文件。</span></font>Cookie<font face="等线"><span lang="zh-CN">通常包含用户身份标识符、城市名称以及一些字符。</span></font>Cookie<font face="等线"><span lang="zh-CN">主要的功能是便于您使用平台产品和服务，以及帮助平台统计访问数据等。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">我们不会将</span></font>cookie<font face="等线"><span lang="zh-CN">用于本隐私政策所述目的之外的任何用途。您可以根据自己的偏好管理或删除</span></font>cookie<font face="等线"><span lang="zh-CN">。有关详情，可以浏览</span></font>aboutcookies.org<font face="等线"><span lang="zh-CN">平台。您可以清除计算机上保存的所有</span></font>cookie<font face="等线"><span lang="zh-CN">，大部分网络浏览器都设有阻止</span></font>cookie<font face="等线"><span lang="zh-CN">的功能。如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，您可能无法使用由我们提供的、依赖于</span></font>Cookie<font face="等线"><span lang="zh-CN">的服务或相应功能。您可以通过更改您的浏览器设置限制我们对</span></font>Cookie<font face="等线"><span lang="zh-CN">的使用。以</span></font>Chrome<font face="等线"><span lang="zh-CN">浏览器为例，您可以在</span></font>Chrome<font face="等线"><span lang="zh-CN">浏览器右上方的下拉菜单的“浏览器设置”中，通过（“【设置】</span></font>-<font face="等线"><span lang="zh-CN">【高级】</span></font>-<font face="等线"><span lang="zh-CN">【清除浏览数据】”），选择“【清除您的</span></font>Cookie<font face="等线"><span lang="zh-CN">】”。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">三、我们如何委托处理、共享、转让、公开披露您的信息</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">委托处理</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">本平台业务功能中某些具体的模块或功能由外部供应商提供。例如我们会聘请服务提供商来协助我们提供客户支持。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">对我们委托处理账号信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、账号信息保护政策以及其他任何相关的保密和安全措施来处理账号信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">共享</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">我们承诺对您的信息进行严格保密，我们不会与任何公司、组织或个人共享您的信息，但以下情况除外：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的账号信息；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）共享的账号信息是去匿名化处理后的脱敏信息，且共享第三方无法重新识别此类信息的信息主体；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的账号信息；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）为了保护信息主体或其他个人生命、财产或社会公众的利益免遭受损害而有必要提供您的信息给第三方；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）与关联主体共享：仅为实现本隐私政策中声明的目的，我们的某些产品或服务将由我们和关联主体共同提供。我们可能会与关联主体共享您的某些账号信息，以提供更好的客户服务和用户体验。例如安排关联主体提供产品或服务，我们仅会出于合法、正当、必要、特定、明确的目的共享您的账号信息，并且只会共享提供产品或服务所必要的账号信息。我们的关联主体无权将共享的账号信息用于与产品或服务无关的其他用途。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">目前，我们的关联主体包括以下类型：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">   
<font face="等线"><span lang="zh-CN">广告、分析服务类的关联主体。除非得到您的许可（您在本平台发布需求、上架产品视为已许可），否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的关联主体共享。我们会向这些关联主体提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。例如，只有在广告主同意遵守我们的广告发布准则后，我们才可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看到广告后安装了应用，或者向这些关联主体提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">   
<font face="等线"><span lang="zh-CN">供应商、服务提供商和其他关联主体。我们将信息发送给支持我们平台的供应商、服务提供商和其他关联主体，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和合法合规性调查。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">转让</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">我们不会将您的账号信息转让给除本平台及关联主体外的任何公司、组织和个人，但以下情形除外：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）事先获得您的单独同意；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）随着我们业务的持续发展，我们有可能涉及合并、分立、清算、资产或业务的收购或出售等交易，您的信息有可能作为此类交易的一部分而被转移，我们将确保信息在转移时的保密性，并要求新的持有您信息的公司、组织继续受本隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">公开披露</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">我们公开披露您的账号信息会采用符合行业内标准的安全保护措施。我们仅会在以下情形下，公开披露您的账号信息：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）获得您的单独同意（您在发布供需信息、广告、服务方案等公示内容时视为已同意将上述信息公开）；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）基于法律法规、法律程序、诉讼、公安、检察院、监察委或政府主管部门强制要求下。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">例外情形</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">在以下情形中，共享、转让、公开披露您的账号信息无需事先征得您的授权同意：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）为订立、履行您作为一方当事人的合同、标识申请、存证证书申请所必需；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）为履行法定义务所必需；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）为应对突发公共卫生事件，或者紧急情况下为保护个人的生命健康和财产安全所必需；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理账号信息；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）在合理的范围内处理您自行公开或者其他已经合法公开的账号信息；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>6<font face="等线"><span lang="zh-CN">）法律、行政法规规定的其他情形；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>7<font face="等线"><span lang="zh-CN">）根据法律规定，共享、转让经去匿名化处理的账号信息，且确保数据接收方无法复原并重新识别账号信息主体的，不属于账号信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">四、我们如何保护您的账号信息</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">本平台非常重视您的信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的账号信息。防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们对网站提供
</span></font>HTTPS
<font face="等线"><span lang="zh-CN">协议安全浏览方式；我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止账号信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护账号信息重要性的认识。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">我们会采取合理可行的措施，尽力避免收集无关的账号信息。我们只会在达成本隐私政策所述目的所需的期限内保留您的账号信息，除非受到法律的允许。超出上述账号信息保存期限后，我们会对您的账号信息进行删除或匿名化处理。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">请使用复杂密码，协助我们保证您的账号安全，我们将尽力保障您发送给我们的任何信息的安全性。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">在不幸发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">如出现本平台产品和服务停止运营的情形，我们会采取合理措施保护您用户信息安全，包括及时停止继续收集用户信息的活动；停止运营的通知将以逐一送达或公告的形式通知用户；并对所持有的账号信息进行删除或匿名化处理，但法律法规另有规定的除外。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">6.<font face="等线"><span lang="zh-CN">原则上，我们在中华人民共和国境内产生或收集的用户信息，将存储在中华人民共和国境内。但是，为向您提供某些产品或服务，在遵守和符合相关法律法规要求的前提下，我们有可能将必要的用户信息传输至境外。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">7.<font face="等线"><span lang="zh-CN">如您基于自身业务目的采购本平台某些境外服务资源，可能会导致您的用户业务数据出境。对此，我们仍将采取充分的保护标准保障您的用户业务数据安全，但由于我们完全按照您的指示进行数据处理，您作为用户业务数据的完全控制方应承担上述的合规义务。如您对此有任何疑问，可通过本隐私政策首页的联系方式与我们联系。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">五、您的权利</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">按照中华人民共和国相关的法律、法规、标准，我们保障您对自己的账号信息行使以下权利：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">1.<font face="等线"><span lang="zh-CN">访问、复制和变更您的账号信息；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">2.<font face="等线"><span lang="zh-CN">删除您的账号信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">您可以通过本隐私政策公布的联系方式，申请删除您的账号信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">在以下情形中，您可以向我们提出删除账号信息的请求：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">例如</span></font>:</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">①<font face="等线"><span lang="zh-CN">如果我们处理账号信息的行为违反法律法规；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">②<font face="等线"><span lang="zh-CN">如果我们处理账号信息的目的已实现、无法实现或者实现处理目的不再必要；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">③<font face="等线"><span lang="zh-CN">如果我们收集、使用您的账号信息，却未征得您的明确同意；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">④<font face="等线"><span lang="zh-CN">如果您撤回您的授权同意；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">⑤<font face="等线"><span lang="zh-CN">如果我们处理账号信息的行为严重违反了与您的约定；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">⑥<font face="等线"><span lang="zh-CN">如果您不再使用我们的产品或服务；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">⑦<font face="等线"><span lang="zh-CN">如果我们永久不再为您提供产品或服务；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">⑧<font face="等线"><span lang="zh-CN">如果您的账号信息保存期限届满；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">⑨<font face="等线"><span lang="zh-CN">法律、行政法规规定的其他情形。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">例外情况：在法律、行政法规规定的保存期限未届满或删除账号信息从技术上难以实现的，我们将无法完全删除您的账号信息。但我们将停止除存储和采取必要的安全保护措施之外的账号信息处理。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的账号信息的主体，要求其及时删除，除非法律法规另有规定或这些主体获得您的独立授权。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">当您从我们的平台中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">3.<font face="等线"><span lang="zh-CN">响应您的上述请求</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">为了保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于合理的请求，我们会积极响应；但对于多次重复、超出合理限度的请求，我们将视情况予以拒绝。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">4.<font face="等线"><span lang="zh-CN">提前获知产品和服务停止运营</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">若因特殊原因导致本平台被迫停止运营，我们将按照法律法规的要求，在产品和</span></font>/<font face="等线"><span lang="zh-CN">或服务的主页面或站内或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您账号信息的收集，同时会按照法律规定对所持有的您的账号信息进行删除或匿名化处理等。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in">5.<font face="等线"><span lang="zh-CN">响应请求的例外</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）与国家安全、国防安全直接相关的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）与公共安全、公共卫生、重大公共利益直接相关的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）与犯罪侦查、起诉、审判和执行判决等直接相关的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）证据表明您可能存在明显恶意、滥用权力及占用平台资源、辱骂客服人员等相关情形的（如您的请求将危害公共安全）；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）您和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>6<font face="等线"><span lang="zh-CN">）响应账号信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>7<font face="等线"><span lang="zh-CN">）涉及商业秘密的。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">六、我们如何处理未成年人的个人信息</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">如果没有父母或监护人的同意，未成年人不得创建自己的用户账号。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">七、本隐私政策的适用及更新</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">我们保留不时更新或修改本隐私政策的权利。我们可能适时修订本隐私政策内容。如该等修订会导致您在本隐私政策项下权利的重大变更，我们将在变更生效前，通过系统推送、电子邮件、短信、弹窗公告等方式通知您。若您不同意该等修订应立即停止使用本平台的产品和服务，或通过本隐私政策公布的联系方式联系我们要求删除您的账号。若您继续使用我们的产品和服务，即表示您同意接受修订后的本隐私政策的约束。本隐私政策所指的重大变更包括但不限于：</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>1<font face="等线"><span lang="zh-CN">）我们的服务模式发生重大变化。如处理信息的目的、处理的信息类型、信息的使用方式等；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>2<font face="等线"><span lang="zh-CN">）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>3<font face="等线"><span lang="zh-CN">）信息共享、转让或公开披露的主要对象发生变化；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>4<font face="等线"><span lang="zh-CN">）您参与信息处理方面的权利及其行使方式发生重大变化；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>5<font face="等线"><span lang="zh-CN">）我们负责处理信息安全的责任部门、联络方式及投诉渠道发生变化时；</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font face="等线"><span lang="zh-CN">（</span></font>6<font face="等线"><span lang="zh-CN">）信息安全影响评估报告表明存在高风险时。</span></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<div style="height:20px"></div>`

// 防抖js
export const throttle = {
    bind: (el, binding) => {
      let throttleTime = binding.value // 防抖时间
      if (!throttleTime) { // 用户若不设置防抖时间，则默认1s
        throttleTime = 1000
      }
      let timer
      let disable = false
      el.addEventListener('click', event => {
        if (timer) {
          clearTimeout(timer)
        }
        if (!disable) { // 第一次执行(一点击触发当前事件)
          disable = true
        } else {
          event && event.stopImmediatePropagation()
        }
        timer = setTimeout(() => {
          timer = null
          disable = false
        }, throttleTime)
      }, true)
    }
  }