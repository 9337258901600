
let Home = ()=> import('../views/home.vue');
let Home1 = ()=> import('../views/home1.vue');

const basicRoutes = [
    {
        path: "/",
        name: "",
        title: '首页',
        redirect: '/search'
    },
    {
        path: "/search",
        name: "search",
        title: '首页',
        redirect: '',
        component: ()=> import('../views/search.vue'),
    },
    {
        path: "/protocol",
        name: "protocol",
        title: '协议',
        redirect: '',
        component: ()=> import('../views/customerManage/protocol/protocolShow.vue'),
    },
    {
        path: "/transfer",
        name: "transfer",
        title: '对外登录',
        redirect: '',
        component: ()=> import('../views/userInfo/transfer.vue'),
    },
    {
        path: "/visition",
        name: "visition",
        title: '演示登录',
        redirect: '',
        component: ()=> import('../views/userInfo/visition.vue'),
    },
    {
        path: "/login",
        name: "login",
        title: '登录',
        redirect: '',
        component: ()=> import('../views/userInfo/login.vue'),
    },
    // 管理端登录
    {
        path: "/loginBack",
        name: "loginBack",
        title: '登录',
        redirect: '',
        component: ()=> import('../views/userInfo/loginBack.vue'),
    },
    {
        path: "/register",
        name: "register",
        title: '注册',
        redirect: '',
        component: ()=> import('../views/userInfo/register.vue'),
    },
    {
        path: "/findPwdBack",
        name: "findPwdBack",
        title: '找回密码',
        redirect: '',
        component: ()=> import('../views/userInfo/findPwdBack.vue'),
    },
    {
        path: "/notFind",
        name: "notFind",
        title: '404',
        redirect: '',
        component: ()=> import('../views/404.vue'),
    },
    // 暂不知道是什么权限访问 TODO
    {
        path: '/idisIntroduce',
        name: 'idisIntroduce',
        component: ()=> import('../views/customerManage/identifyResolution/idisIntroduce.vue'),
    },{
        path: '/idisRegister',
        name: 'idisRegister',
        component: ()=> import('../views/customerManage/identifyResolution/idisRegister.vue'),
    },
    {
        path: '/idisParsingInfo',
        name: 'idisParsingInfo',
        component: ()=> import('../views/customerManage/identifyResolution/idisParsingInfo.vue'),
    },
    {
        path: '/idisServeInfo',
        name: 'idisServeInfo',
        component: ()=> import('../views/customerManage/identifyResolution/idisServeInfo.vue'),
    },
    {
        path: '/idisRecord',
        name: 'idisRecord',
        component: ()=> import('../views/customerManage/identifyResolution/idisRecord.vue'),
        children: [
            {
                path: '/idisRegisterRecord',
                name: 'idisRegisterRecord',
                component: ()=> import('../views/customerManage/identifyResolution/idisRegisterRecord.vue'),
            },
            {
                path: '/idisParsingRecord',
                name: 'idisParsingRecord',
                component: ()=> import('../views/customerManage/identifyResolution/idisParsingRecord.vue'),
            },
            {
                path: '/idisServeRecord',
                name: 'idisServeRecord',
                component: ()=> import('../views/customerManage/identifyResolution/idisServeRecord.vue'),
            }
        ]
    },
    // 企业端 ===================================================
    {
        path: "/identificationDetailOut",
        name: "identificationDetailOut",
        title: '标识详情',
        redirect: '',
        component: ()=> import('../views/identification/identificationDetailOut.vue')
    },
    {
        path: "/netIndex1",
        name: "netIndex1",
        title: '首页',
        redirect: '',
        component: Home1,
        children: [
            {
                path: "/netIndex",
                name: "netIndex",
                title: '首页',
                redirect: '',
                component: ()=> import('../views/customerManage/customerHome.vue'),
            },
        ]
    },
    {
        path: "/enterprise",
        name: "enterprise",
        title: '企业管理',
        redirect: '/enterprise/prefix',
        component: Home,
        children: [
            {
                path: "prefixDetail",
                name: "prefixDetail1",
                title: '前缀详情',
                component: ()=> import('../views/customerManage/operation/prefixDetail.vue'),
            },
            {
                path: 'prefixSetting',
                name: 'prefixSetting1',
                title: '前缀配置',
                component: ()=> import('../views/customerManage/operation/prefixSetting.vue'),
            },
            {
                path: 'depositSetting',
                name: 'depositSetting1',
                title: '托管配置',
                component: ()=> import('../views/customerManage/operation/depositSetting.vue'),
            },
            {
                path: 'depositDetail',
                name: 'depositDetail2',
                title: '托管配置详情',
                component: ()=> import('../views/customerManage/operation/depositDetail.vue'),
            },
            {
                path: 'changeRecordsList',
                name: 'changeRecordsList2',
                title: '变更记录',
                component: ()=> import('../views/customerManage/operation/changeRecordsList.vue'),
            },
            {
                path: 'registerInfo',
                name: 'registerInfo',
                title: '注册信息',
                component: ()=> import('../views/customerManage/operation/registerInfo.vue'),
            }
        ]
    },
    {
        path: "/sign",
        name: "sign",
        title: '标识管理',
        redirect: '/sign/identificationList',
        component: Home,
        children: [
            {
                path: "identificationList",
                name: "identificationList",
                title: '标识列表',
                component: ()=> import('../views/customerManage/operation/identificationList.vue'),
            },
            {
                path: "identificationDetail",
                name: "identificationDetail",
                title: '标识详情',
                component: ()=> import('../views/customerManage/operation/identificationDetail.vue'),
            },
            {
                path: "identificationAdd",
                name: "identificationAdd",
                title: '标识添加',
                component: ()=> import('../views/customerManage/operation/identificationAdd.vue'),
            },
            {
                path: "identificationEdit",
                name: "identificationEdit",
                title: '标识编辑',
                component: ()=> import('../views/customerManage/operation/identificationEdit.vue'),
            },
            {
                path: 'dataTemplate',
                name: 'dataTemplate',
                title: '数据模板',
                component: ()=> import('../views/customerManage/operation/dataTemplate.vue'),
            },
            {
                path: 'dataTemplateAdd',
                name: 'dataTemplateAdd',
                title: '数据模板添加',
                component: ()=> import('../views/customerManage/operation/dataTemplateAdd.vue'),
            },
            {
                path: 'dataTemplateDetail',
                name: 'dataTemplateDetail',
                title: '数据模板详情',
                component: ()=> import('../views/customerManage/operation/dataTemplateDetail.vue'),
            }
        ]
    },
    // 产品管理
    {
        path: "/product",
        name: "product",
        title: '产品管理',
        redirect: '/product/productionList',
        component: Home,
        children: [
            {
                path: "productionList",
                name: "productionList",
                title: '产品列表',
                component: ()=> import('../views/customerManage/operation/productionList.vue'),
            },
            {
                path: "productionDetail",
                name: "productionDetail",
                title: '产品详情',
                component: ()=> import('../views/customerManage/operation/productionDetail.vue'),
            },
            {
                path: "productionAdd",
                name: "productionAdd",
                title: '产品添加',
                component: ()=> import('../views/customerManage/operation/productionAdd.vue'),
            },
            {
                path: "productionEdit",
                name: "productionEdit",
                title: '产品编辑',
                component: ()=> import('../views/customerManage/operation/productionEdit.vue'),
            },
            {
                path: 'productTemplate',
                name: 'productTemplate',
                title: '产品模板',
                component: ()=> import('../views/customerManage/operation/productTemplate.vue'),
            },
            {
                path: 'productTemplateAdd',
                name: 'productTemplateAdd',
                title: '数据模板添加',
                component: ()=> import('../views/customerManage/operation/productTemplateAdd.vue'),
            },
            {
                path: 'productTemplateDetail',
                name: 'productTemplateDetail',
                title: '数据模板详情',
                component: ()=> import('../views/customerManage/operation/productTemplateDetail.vue'),
            }
        ]
    },
    {
        path: "/cooperEnterprise",
        name: "cooperEnterprise",
        title: '合作企业',
        redirect: '/cooperEnterprise/cooperEnterpriseList',
        component: Home,
        children: [
            {
                path: "cooperEnterpriseList",
                name: "cooperEnterpriseList",
                title: '合作企业',
                component: ()=> import('../views/customerManage/cooperativeEnterprise/cooperEnterpriseList.vue'),
            },
            {
                path: "cooperativeIdentify",
                name: "cooperativeIdentify",
                title: '合作标识',
                component: ()=> import('../views/customerManage/cooperativeEnterprise/cooperativeIdentify.vue'),
            },
            {
                path: "cooperativeIdentifyDetail",
                name: "cooperativeIdentifyDetail",
                title: '合作标识详情',
                component: ()=> import('../views/customerManage/cooperativeEnterprise/cooperativeIdentifyDetail.vue'),
            },
            {
                path: "cooperEnterPriseAdd",
                name: "cooperEnterPriseAdd",
                title: '新增企业',
                component: ()=> import('../views/customerManage/cooperativeEnterprise/cooperEnterPriseAdd.vue'),
            },
            {
                path: "cooperEnterPriseEdit",
                name: "cooperEnterPriseEdit",
                title: '编辑/查看企业',
                component: ()=> import('../views/customerManage/cooperativeEnterprise/cooperEnterPriseEdit.vue'),
            },
        ]
    },
    // 存证管理(企业端)
    {
        path: "/depositoryManagement",
        name: "depositoryManagement",
        title: '存证管理',
        redirect: '/depositoryManagement/depositoryManagementList',
        component: Home,
        children: [
            {
                path: "depositoryManagementList",
                name: "depositoryManagementList",
                title: '存证列表',
                component: ()=> import('../views/customerManage/depositoryManagement/depositoryManagementList.vue'),
            },
            {
                path: "depositoryPayed",
                name: "depositoryPayed",
                title: '已购存证',
                component: ()=> import('../views/customerManage/depositoryManagement/depositoryPayed.vue'),
            },
        ]
    },
    {
        path: "/cooperEnt",
        name: "cooperEnt",
        title: '合作标识2',
        redirect: '/cooperEnt/cooperativeIdentify2',
        component: Home1,
        children: [
            {
                path: "cooperativeIdentify2",
                name: "cooperativeIdentify2",
                title: '合作标识2',
                component: ()=> import('../views/customerManage/cooperativeEnterprise/cooperativeIdentify.vue'),
            },          
        ]
    },
    {
        path: "/prefabCodeManage",
        name: "prefabCodeManage",
        title: '预制码管理',
        redirect: '/prefabCodeManage/prefabCode',
        component: Home,
        children: [
            {
                path: "prefabCode",
                name: "prefabCode",
                title: '预制码',
                component: ()=> import('../views/customerManage/prefabCodeManage/prefabCode.vue'),
            },          
            {
                path: "prefabCodeDownload",
                name: "prefabCodeDownload",
                title: '预制码下载',
                component: ()=> import('../views/customerManage/prefabCodeManage/prefabCodeDownload.vue'),
            },          
        ]
    },
    {
        path: "/batch",
        name: "batch",
        title: '批次管理',
        redirect: '/batch/batchList',
        component: Home,
        children: [
            {
                path: "batchList",
                name: "batchList",
                title: '批次列表',
                component: ()=> import('../views/customerManage/batch/batchList.vue'),
            }
        ]
    },
    {
        path: "/qualityTrace",
        name: "qualityTrace",
        title: '质量追溯',
        redirect: '/qualityTrace/qualityTraceList',
        component: Home,
        children: [
            {
                path: "qualityTraceList",
                name: "qualityTraceList",
                title: '追溯模板列表',
                component: ()=> import('../views/customerManage/qualityTrace/qualityTraceList.vue'),
            },
            {
                path: "qualityTraceEdit",
                name: "qualityTraceEdit",
                title: '追溯模板编辑',
                component: ()=> import('../views/customerManage/qualityTrace/qualityTraceEdit.vue'),
            }
        ]
    },
    {
        path: "/supplyChainManage",
        name: "supplyChainManage",
        title: '供应链管理',
        redirect: '/supplyChainManage/supplyChainList',
        component: Home,
        children: [
            {
                path: "supplyChainList",
                name: "supplyChainList",
                title: '供应链列表',
                component: ()=> import('../views/customerManage/supplyChainManage/supplyChainList.vue'),
            },
            {
                path: "supplyChainSignList",
                name: "supplyChainSignList",
                title: '供应链标识列表',
                component: ()=> import('../views/customerManage/supplyChainManage/supplyChainSignList.vue'),
            }
        ]
    },
    {
        path: "/charge",
        name: "charge",
        title: '收费管理',
        redirect: '/charge/chargeList',
        component: Home,
        children: [
            {
                path: "chargeList",
                name: "chargeList",
                title: '收费列表',
                component: ()=> import('../views/customerManage/financial/chargeStandard.vue'),
            },
            {
                path: "chargeDetail",
                name: "chargeDetail",
                title: '收费列表详情',
                component: ()=> import('../views/customerManage/financial/chargeDetail.vue'),
            }
        ]
    },
    {
        path: '/systemMgt',
        name: 'systemMgt',
        title: '系统管理',
        redirect: '/systemMgt/department',
        component: Home,
        children: [
            {
                path: 'department',
                name: 'department',
                title: '部门管理',
                component: ()=> import('../views/customerManage/systemMgt/department.vue'),
            },
            {
                path: 'members',
                name: 'members',
                title: '成员管理',
                component: ()=> import('../views/customerManage/systemMgt/members.vue'),
            },
            {
                path: 'roles',
                name: 'roles',
                title: '角色管理',
                component: ()=> import('../views/customerManage/systemMgt/roles.vue'),
            },
        ]
    },
    {
        path: '/selfCenter',
        name: 'selfCenter',
        title: '个人中心',
        redirect: '/selfCenter/userInfo',
        component: Home,
        children: [
            {
                path: 'userInfo',
                name: 'userInfo1',
                title: '基本信息',
                component: ()=> import('../views/customerManage/platform/userInfo.vue'),
            },
            {
                path: 'updatePassword',
                name: 'updatePassword1',
                title: '修改密码',
                component: ()=> import('../views/customerManage/platform/updatePassword.vue'),
            },
            {
                path: 'loginLog',
                name: 'loginLog1',
                title: '登录日志',
                component: ()=> import('../views/customerManage/platform/loginLog.vue'),
            }
        ]
    },
    {
        path: '/myInfo',
        name: 'myInfo',
        title: '个人中心',
        redirect: '/myInfo/userInfo',
        component: Home1,
        children: [
            {
                path: 'userInfo',
                name: 'userInfo2',
                title: '基本信息',
                component: ()=> import('../views/customerManage/platform/userInfo.vue'),
            },
            {
                path: 'updatePassword',
                name: 'updatePassword2',
                title: '修改密码',
                component: ()=> import('../views/customerManage/platform/updatePassword.vue'),
            },
            {
                path: 'loginLog',
                name: 'loginLog2',
                title: '登录日志',
                component: ()=> import('../views/customerManage/platform/loginLog.vue'),
            }
        ]
    },
    {
        path: '/enterpriseInfo',
        name: 'enterpriseInfo2',
        title: '企业信息',
        redirect: '/enterpriseInfo/enterpriseInfos',
        component: Home,
        children: [
            {
                path: 'enterpriseInfos',
                name: 'enterpriseInfos',
                title: '企业信息',
                component: ()=> import('../views/customerManage/enterpriseInfo/enterpriseInfos.vue'),
            }
        ]
    },
    // 管理端 ===================================================
    {
        path: "/adminIndex1",
        name: "adminIndex1",
        title: '首页',
        redirect: '',
        component: Home1,
        children: [
            {
                path: "/adminIndex",
                name: "adminIndex",
                title: '首页',
                redirect: '',
                component: ()=> import('../views/adminManage/adminHome.vue'),
            },
        ]
    },
    {
        path: "/business",
        name: "business",
        title: '企业管理',
        redirect: '/business/enterpriseList',
        component: Home,
        children: [
            {
                path: "enterpriseList",
                name: "enterpriseList",
                title: '企业列表',
                component: ()=> import('../views/adminManage/business/enterpriseList.vue'),
            },
            {
                path: "enterpriseInfo",
                name: "enterpriseInfo",
                title: '企业详情',
                component: ()=> import('../views/adminManage/business/enterpriseInfo.vue'),
            },
            {
                path: "enterpriseVerify",
                name: "enterpriseVerify",
                title: '企业审核列表',
                component: ()=> import('../views/adminManage/business/enterpriseVerify.vue'),
            },
            {
                path: "enterpriseVerifyInfo",
                name: "enterpriseVerifyInfo",
                title: '企业审核',
                component: ()=> import('../views/adminManage/business/enterpriseVerifyInfo.vue'),
            },
            {
                path: "prefix",
                name: "prefix",
                title: '前缀管理',
                component: ()=> import('../views/adminManage/business/prefix.vue'),
            },
            {
                path: "prefixDetail",
                name: "prefixDetail",
                title: '前缀详情',
                component: ()=> import('../views/adminManage/business/prefixDetail.vue'),
            },
            {
                path: 'prefixSetting',
                name: 'prefixSetting',
                title: '前缀配置',
                component: ()=> import('../views/adminManage/business/prefixSetting.vue'),
            },
            {
                path: 'depositSetting',
                name: 'depositSetting',
                title: '托管配置',
                component: ()=> import('../views/adminManage/business/depositSetting.vue'),
            },
            {
                path: 'depositDetail',
                name: 'depositDetail1',
                title: '托管配置详情',
                component: ()=> import('../views/adminManage/business/depositDetail.vue'),
            },
            {
                path: 'changeRecordsList',
                name: 'changeRecordsList',
                title: '变更记录',
                component: ()=> import('../views/adminManage/business/changeRecordsList.vue'),
            }
        ]
    },
    {
        path: "/mark",
        name: "mark",
        title: '标识管理',
        redirect: '/mark/markList',
        component: Home,
        children: [
            {
                path: "markList",
                name: "markList",
                title: '标识列表',
                component: ()=> import('../views/adminManage/business/mark.vue'),
            },
            {
                path: "markDetail",
                name: "markDetail",
                title: '标识列表',
                component: ()=> import('../views/adminManage/business/markDetail.vue'),
            }
        ]
    },
    {
        path: "/serve",
        name: "serve",
        title: '服务单位管理',
        redirect: '/serve/serveList',
        component: Home,
        children: [
            {
                path: "serveList",
                name: "serveList",
                title: '服务单位列表',
                component: ()=> import('../views/adminManage/business/serve.vue'),
            }
        ]
    },
    {
        path: "/user",
        name: "user",
        title: '用户管理',
        redirect: '/user/userList',
        component: Home,
        children: [
            {
                path: "userList",
                name: "userList",
                title: '用户列表',
                component: ()=> import('../views/adminManage/platform/userList.vue'),
            }
        ]
    },
    {
        path: "/role",
        name: "role",
        title: '角色管理',
        redirect: '/role/roleList',
        component: Home,
        children: [
            {
                path: "roleList",
                name: "roleList",
                title: '角色列表',
                component: ()=> import('../views/adminManage/platform/roleList.vue'),
            }
        ]
    },
    {
        path: "/rolePermission",
        name: "rolePermission",
        title: '权限管理',
        redirect: '/rolePermission/rolePermissionList',
        component: Home,
        children: [
            {
                path: "rolePermissionList",
                name: "rolePermissionList",
                title: '权限版本',
                component: ()=> import('../views/adminManage/platform/rolePermissionList.vue'),
            }
        ]
    },
    // 存证管理(管理端)
    {
        path: "/depositoryManagementAdmin",
        name: "depositoryManagementAdmin",
        title: '存证管理',
        redirect: '/depositoryManagementAdmin/depositoryManagementList',
        component: Home,
        children: [
            {
                path: "quotaSet",
                name: "quotaSet",
                title: '额度配置',
                component: ()=> import('../views/adminManage/depositoryManagement/quotaSet.vue'),
            },
            {
                path: "modelSet",
                name: "modelSet",
                title: '模板配置',
                component: ()=> import('../views/adminManage/depositoryManagement/modelSet.vue'),
            },
            {
                path: "modelAdd",
                name: "modelAdd",
                title: '新增模板',
                component: ()=> import('../views/adminManage/depositoryManagement/modelAdd.vue'),
            },
            {
                path: "modelDetail",
                name: "modelDetail",
                title: '模板详情',
                component: ()=> import('../views/adminManage/depositoryManagement/modelDetail.vue'),
            },
            {
                path: "depositoryManagementList",
                name: "depositoryManagementList",
                title: '存证列表',
                component: ()=> import('../views/adminManage/depositoryManagement/depositoryManagementList.vue'),
            },
            {
                path: "depositoryPayed",
                name: "depositoryPayed",
                title: '已购存证',
                component: ()=> import('../views/adminManage/depositoryManagement/depositoryPayed.vue'),
            }
        ]
    },
    {
        path: "/financialReport",
        name: "financialReport",
        title: '财务报表',
        redirect: '/financialReport/paymentList',
        component: Home,
        children: [
            {
                path: "paymentList",
                name: "paymentList",
                title: '缴费管理',
                component: ()=> import('../views/adminManage/financial/paymentList.vue'),
            },
            {
                path: "financialEdit",
                name: "financialEdit",
                title: '缴费编辑',
                component: ()=> import('../views/adminManage/financial/financialEdit.vue'),
            },
            {
                path: "financialDetail",
                name: "financialDetail",
                title: '缴费详情',
                component: ()=> import('../views/adminManage/financial/financialDetail.vue'),
            }
        ]
    },
    {
        path: "/financial",
        name: "financial",
        title: '收费管理',
        redirect: '/financial/chargeTypeList',
        component: Home,
        children: [
            {
                path: "chargeTypeList",
                name: "chargeTypeList",
                title: '收费类型列表',
                component: ()=> import('../views/adminManage/financial/chargeTypeList.vue'),
            },
            {
                path: "chargeStandard",
                name: "chargeStandard",
                title: '收费标准列表',
                component: ()=> import('../views/adminManage/financial/chargeStandard.vue'),
            },
            {
                path: "enterpriseList",
                name: "enterpriseList2",
                title: '收费企业列表',
                component: ()=> import('../views/adminManage/financial/enterpriseList.vue'),
            }
        ]
    },
    {
        path: '/userCenter',
        name: 'userCenter',
        title: '个人中心',
        redirect: '/userCenter/userInfo',
        component: Home,
        children: [
            {
                path: 'userInfo',
                name: 'userInfo',
                title: '基本信息',
                component: ()=> import('../views/adminManage/platform/userInfo.vue'),
            },
            {
                path: 'updatePassword',
                name: 'updatePassword',
                title: '修改密码',
                component: ()=> import('../views/adminManage/platform/updatePassword.vue'),
            },
            {
                path: 'loginLog',
                name: 'loginLog',
                title: '登录日志',
                component: ()=> import('../views/adminManage/platform/loginLog.vue'),
            }
        ]
    },
    {
        path:"/picPreView",
        name:"picPreView",
        title:"预览",
        redirect:"",
        component:() => import('../views/commonUI/picPreView.vue')
    },
    {
        path: "/secendChart",
        name: "secendChart",
        title: '可视化',
        redirect: '',
        component: ()=> import('../views/adminManage/secendChart/secendChart.vue'),
        // children: [
        //     {
        //         path: "/secendChart",
        //         name: "/secendChart",
        //         title: '可视化',
        //         component: ()=> import('../views/adminManage/secendChart/secendChart.vue'),
        //     }
        // ]
    }
]

export default basicRoutes;